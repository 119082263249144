<template>
  <header
    class="Header"
    :class="{'Header--height': $i18n.locale === 'ru'}"
  >
    <div class="Wrapper">
      <div class="Header-TitleWrapper">
        <h1
            id="header-title"
            class="Title Title--h1 Header-Title typing"
            :class="[
              {'Title--h1-smaller': $i18n.locale === 'ru'},
              {'Header-Title--padding': $i18n.locale === 'en'}
            ]"
        ></h1>  
        <div
            class="SubTitle Header-SubTitle "
            :class="[
                  {'SubTitle-smaller': $i18n.locale === 'ru'},
                  {'TextUpper': $i18n.locale === 'en'}
        ]"
        ></div>  
      </div>
      <a
          v-show="$i18n.locale === 'ru'"
          href
          class="Button Button--yellow Header-Btn"
          @click.prevent="scrollTo('form')"
      >
        <span class="Button-Text">
          Присоединяйся к команде
        </span>
      </a>
      <transition name="animation"
                  enter-active-class="animate__animated animate__fadeInRight"
                  leave-active-class="animate__animated animate__fadeOutRight"
      >
        <div v-show="show" class="Header-Features">
          <h4 class="Title Title--h4 Header-FeaturesTitle">
            key features
          </h4>
          <p class="Text Header-FeaturesText">
            Largest Performance Marketing Agency
            in Europe, according to Appsflyer
          </p>
        </div>
      </transition>
      <img v-show="$screen.width > 959" src="@/assets/images/header-dots.png" alt="bg" class="Header-BgDots">
      <img v-show="$screen.width < 959" src="@/assets/images/header-dots-mobile.svg" alt="bg" class="Header-BgDots"
           :class="{'Header-BgDots--top': $i18n.locale === 'ru'}"
      >
      <div v-show="$screen.width > 959" class="Header-Lighting"></div>
      <LottieAnimation
          v-show="$screen.width > 959"
          class="Header-SphereImg"
          ref="anim"
          :animationData="require('@/assets/animations/circle-animation.json')"
          background="transparent"
          loop
          autoPlay
      />
    </div>
  </header>
</template>

<script>
import LottieAnimation from 'lottie-web-vue'
import {showModal, textAnimation, scrollTo} from "../assets/utils/utils";
import {mapState} from 'vuex'

export default {
  name: "TheHeader",
  components: { LottieAnimation },
  data() {
    return {
      show: this.$i18n.locale === 'en',
      isTextTyped: false
    }
  },
  computed: {
    ...mapState(['isLoading']),
  },
  methods: {
    showModal,
    scrollTo
  },
  mounted() {
    const texts = [
      {
        selector: '.Header-Title',
        text: this.$t('header.title')
      },
      {
        selector: '.Header-SubTitle',
        text: this.$t('header.subtitle')
      }
    ]

    textAnimation(texts)
  }
}
</script>

<style lang="scss">
.Header {
  position: relative;
  height: 588px;
  padding: 0;
  text-align: center;

  @media (min-width: $screen-l) {
    height: auto;
    padding-bottom: 45px;
    background-image: url(~@/assets/images/header-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    text-align: left;
  }

  &--height {
    height: 435px;

    @media (min-width: $screen-l) {
      height: auto;
    }
  }

  &-TitleWrapper {
    height: 245px;

    @media (min-width: $screen-l) {
      height: 500px;
    }
    @media (min-width: $screen-xl) {
      height: 284px;
    }
  }

  &-SubTitle {
    margin-bottom: 16px;

    @media (min-width: $screen-l) {
      margin-bottom: 24px;
      display: inline;
    }
  }

  &-Title {
    @media (min-width: $screen-l) {
      display: inline;
    }

    &--padding {
      padding: 0 40px;
      @media (min-width: $screen-l) {
        padding: 0;
      }
    }
  }

  &-Btn {
    left: 50%;
    transform: translate(-50%, 0);
    height: 48px;
    margin-top: 6px;
    font-size: 16px;

    @media (min-width: $screen-l) {
      left: 0;
      transform: translate(0, 0);
      margin-top: 30px;
    }
    @media (min-width: $screen-xl) {
      margin-top: 35px;
    }
  }

  &-Features {
    margin-top: 18px;

    @media (min-width: $screen-xl) {
      width: 209px;
      position: absolute;
      right: 0;
      bottom: -123px;
      margin-top: 0;
    }
  }

  &-FeaturesTitle {
    display: none;
    @media (min-width: $screen-l) {
      display: block;
      margin-bottom: 16px;
    }
  }

  &-FeaturesText {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.33;
    @media (min-width: $screen-l) {
      font-size: 16px;
      line-height: 160%;
    }
  }

  &-BgDots {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    opacity: 1;

    &--top {
      top: 27%;
      @media (min-width: $screen-l) {
        top: 50%;
      }
    }
  }

  &-Lighting {
    position: absolute;
    z-index: -3;
    top: 50%;
    right: 70px;
    transform: translate(0, -50%);
    width: 300px;
    height: 300px;
    background: var(--color-text-main2);
    border-radius: 50%;
    box-shadow: 1px 1px 1000px var(--color-text-main2);
    filter: blur(30px);
    opacity: .2;

    @media (min-width: $screen-xl) {
      right: 130px;
      box-shadow: 1px 1px 1000px var(--color-text-main2);
      filter: blur(60px);
    }
  }

  &-SphereImg {
    position: absolute;
    z-index: -1;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    opacity: .5;

    @media (min-width: $screen-xl) {
      right: 70px;
    }
  }

  .typed-element {
    display: inline;
  }
}
</style>